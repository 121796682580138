@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import url('https://fonts.googleapis.com/css?family=Teko&display=swap');
@import url('https://fonts.googleapis.com/css?family=Open+Sans|Open+Sans+Condensed:300&display=swap');
@import url('https://fonts.googleapis.com/css?family=Encode+Sans+Expanded:700&display=swap');

body{
    margin: 0px;
    background-color: #fdfdfd
}

.MainContent{
    width: 960px;
    margin: 0 auto;
    background-color: #f7f7f7
}

.Navbar{    
    z-index: 10;
    box-shadow: none;
    padding: 10px;
    height: 80px;
}
.NavBarContainer img {
    margin: 20px;
}

.NavBarContainer{
    display: flex;
    background-color: white;
}

.HeaderText{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 12pt;
    padding: 20px;
}

.Logo{
    height: 35px;
}

h1{    
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 12pt;
    padding-top: 20px;
    display: inline;
}