

.Rating-Inline{
    display: inline-block;
    position: relative;
}

.Rating-Inline-Text{
    font-family: Encode Sans Expanded;
    font-weight: 700;
    font-size: 14px;
    color: darkblue;
}