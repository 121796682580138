.Books{
    display: flex;
    justify-content: space-around;
    align-items: stretch;
    flex-wrap: wrap;
    flex: 1 0 auto;
  }

.BookItem-Detail{
    padding: 20px;
    margin-left: auto;
    margin-right: auto;
    max-width: 600px;
}

.BookItem-Detail-Footer
{
    padding-top:50px;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
}
.BookItem-Detail-Separator{
    width: 2px;
    background-color: grey;
    height: 150px;
    margin: 5px;
}

.BookItem-Detail-Sequence{
    position: absolute;
    left: -275px;
    top: 0px;
    width: 70px;
    text-align: right;
    font-family: Teko;
    color: orange;
    font-size: 64px;
    float: left;
}

.BookItem-Detail-Rating{
    position: absolute;
    right: -75px;
    top: 0px;
    width: 65px;
    height: 65px;
    font-family: Encode Sans Expanded;
    font-weight: 700;
    font-size: 16px;
    color: white;
    background-color: darkblue;
    border-radius: 50%;
    float: left;
    display: flex;
    align-items: center;
    margin: auto;
    justify-content: center;
    z-index: 100;
}

.BookItem-Detail-Title{
    font-size: 22pt;
    font-family: Open Sans Condensed;
}

.BookItem-Thumb{
    padding: 10px;
}

.Book-Item{
    position: relative;
    margin: 50px;
    margin-left: 50px;
    width: 200px;
    text-align: left;
    flex: 1 0 auto;
    min-height: 300px;
  }

.Book-Sequence{
    position: absolute;
    left: -80px;
    top: -10px;
    width: 70px;
    text-align: right;
    font-family: Teko;
    color: orange;
    font-size: 64px;
    float: left;
}

.Book-Rating{
    position: absolute;
    right: -15px;
    top: -15px;
    width: 35px;
    height: 35px;
    font-family: Encode Sans Expanded;
    font-weight: 700;
    font-size: 12px;
    color: white;
    background-color: darkblue;
    border-radius: 50%;
    float: left;
    display: flex;
    align-items: center;
    margin: auto;
    justify-content: center;
}

.Book-Cover{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    margin-left: auto;
    margin-right: auto;
}

.Book-Title{
    font-size: 22pt;
    font-family: Open Sans Condensed;
}

.Book-Author{
    font-size: 12pt;
    font-family: Open Sans ;
}

.Book-Detail{
    font-size: 10pt;
    font-family: Open Sans;
}